<template>
  <GroupRoomingList
    v-if="group"
    :current-group="group"
    :current-group-id="group.id"
  />
</template>

<script>
import GroupRoomingList from './GroupRoomingList/GroupRoomingList.vue';

export default {
  components: { GroupRoomingList },
  computed: {
    group() {
      return this.$store.state["app-groups"]?.currentGroup;
    },
  },
};
</script>
